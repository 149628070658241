import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
interface Props {
  message: string
  spinnerOn?: boolean
}

const MessageBox: React.FC<Props> = ({ message, spinnerOn }) => {
  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="subtitle2" color="primary">
        {message}
      </Typography>
    </Box>
  )
}

export default MessageBox
