import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { auth } from '../utils/firebase'
import { i18next } from '../utils/i18n'
import { DAILY_ACCESS_TOKEN } from '../utils/constants'
import MessageBox from '../modules/components/MessageBox'
const App = React.lazy(() => import('../App'))

export interface IAppContext {
  user: any | undefined
  userLoading: boolean
}

export const initContext: IAppContext = {
  user: undefined,
  userLoading: false,
}

export const AppContext = createContext<IAppContext>(initContext)

export const AppContextProvider: FunctionComponent = ({ children }) => {
  const [token, setToken] = useState('')
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    auth().onAuthStateChanged(async user => {
      setCurrentUser(user)
      if (user) {
        const idToken = await user.getIdToken()
        console.log(idToken)
        localStorage.setItem(DAILY_ACCESS_TOKEN, idToken)
        setToken(idToken)
      } else setToken('')
      setLoading(false)
    })
  }, [])

  const context: IAppContext = {
    user: currentUser,
    userLoading: loading,
  }
  return (
    <AppContext.Provider value={context}>
      {children}

      <React.Suspense
        fallback={
          <MessageBox message={i18next.t('LOADING')} spinnerOn={true} />
        }
      >
        <App idToken={token} />
      </React.Suspense>
    </AppContext.Provider>
  )
}
