import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

export const defaultLanguage = localStorage.i18nextLng || 'en-US'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: false,
    resources: {
      'en-US': {
        translation: {
          HOME: 'Dashboard',
          ACCOUNT: 'Account',
          CLOSE: 'Close',
          CANCEL: 'Cancel',
        },
      },
      es: {
        translation: {
          HOME: 'Inicio',
          ACCOUNT: 'Mi Cuenta',
          LOGIN_TO_CONTINUE: 'Para continuar, introducir credenciales.',
          SIGN_OUT: 'Salir',
        },
      },
    },
  })
  .catch(e => console.error('Error initializing i18n', e))

export { i18n as i18next }
